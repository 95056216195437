<template>
    <auth-layout pattern="blue">
        <h1 class="fw-bold mb-3">
            {{ $t('Nastavenie hesla') }}
        </h1>
        <form @submit.prevent="submitForm">
            <div class="form-floating mb-2">
                <input id="password" v-model="input.password.value" :class="'form-control ' + isValidInput(input.password)" placeholder="" type="password">
                <label for="password">{{ $t('Heslo') }}</label>
            </div>
            <div class="form-floating mb-3">
                <input id="password_confirmation" v-model="input.password_confirmation.value" :class="'form-control ' + isValidInput(input.password_confirmation)" placeholder="" type="password">
                <label for="password_confirmation">{{ $t('Potvrdenie hesla') }}</label>
                <p class="fw-bold form-text text-center text-dark mt-3">
                    {{ $t('Heslo musí byť dlhé minimálne 6 znakov, obsahovať 1 veľké písmeno a 1 číslicu') }}
                </p>
            </div>
            <div class="text-center">
                <submit-button :disabled="! canSubmit || fetching" :fetching="fetching" :title="submitButtonTitle"></submit-button>
            </div>
        </form>
    </auth-layout>
</template>

<script>
import v8n from 'v8n'
import SubmitButton from '../component/SubmitButton'
import AuthLayout from '../layout/AuthLayout'
import { canSubmitForm } from '@/util/canSubmitForm'
import { prepareData } from '@/util/prepareData'
import { formInput } from '@/util/formInput'
import { validInput } from '@/util/validInput'
import { PASSWORD_REGEX } from '@/util/regex'
import router from '../router'
import { fromUnixTime, isAfter } from 'date-fns'
import { toast } from '@/util/toast'

export default {
    beforeDestroy () {
        if (this.timer) {
            clearInterval(this.timer)
        }
    },
    components: { AuthLayout, SubmitButton },
    computed: {
        canSubmit () {
            return canSubmitForm(this.input)
        },
        fetching () {
            return this.$store.getters['auth/isFetching']
        },
        submitButtonTitle () {
            return this.$t('Nastaviť heslo')
        },
        urlParams () {
            return new URLSearchParams(window.location.search)
        },
    },
    data () {
        return {
            date: new Date(),
            input: {
                password: formInput({
                    validation: v8n()
                        .pattern(PASSWORD_REGEX),
                }),
                password_confirmation: formInput({
                    validation: v8n()
                        .pattern(PASSWORD_REGEX),
                }),
            },
            timer: null,
        }
    },
    methods: {
        isValidInput (input) {
            return validInput(input)
        },
        submitForm () {
            if (! this.canSubmit) return
            
            this.$store.dispatch('auth/resetPassword', {
                payload: prepareData(this.input),
                params: this.urlParams,
            })
        },
    },
    mounted () {
        const urlParams = this.urlParams
        
        if (
            ! urlParams.has('user') ||
            ! urlParams.has('signature')
        ) {
            router.push({ name: 'login' })
        }
        
        if (urlParams.has('expires')) {
            const expireAt = fromUnixTime(parseInt(urlParams.get('expires')))
            
            this.timer = setInterval(function () {
                if (isAfter(new Date(), expireAt)) {
                    toast.danger(this.$t('Platnosť obnovenia hesla vypršala'))
                    router.push({ name: 'login' })
                }
            }, 1000)
        }
    },
    name: 'ResetPassword',
    watch: {
        'input.password.value': function (value) {
            this.input.password.dirty = true
            this.input.password.valid = this.input.password.validation?.test(value)
            
            const passwordConfirmation = this.input.password_confirmation
            
            passwordConfirmation.valid = passwordConfirmation.validation?.exact(value)
                                                             ?.test(passwordConfirmation.value)
        },
        'input.password_confirmation.value': function (value) {
            this.input.password_confirmation.dirty = true
            this.input.password_confirmation.valid = this.input.password_confirmation.validation?.exact(this.input.password.value)
                                                         ?.test(value)
        },
    },
}
</script>
